import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const CalloutStrip = loadable(() =>
    import('/src/components/PageBuilder/layouts/standard/CalloutStrip')
)

const JoinStrip = ({ backgroundColour }) => {
    const globalQuery = useStaticQuery(
        graphql`
            query {
                component: allWp {
                    nodes {
                        globalBlocks {
                            joinStrip {
                                fieldGroupName
                                joinStrip {
                                    content
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const globalData = globalQuery.component.nodes

    return globalData.map((node, index) => {
        let componentData = node.globalBlocks.joinStrip.joinStrip
        const blockSettings = {
            paddingSize: 'sm'
        }

        return (
            <CalloutStrip
                key={index}
                backgroundColour={backgroundColour}
                blockSettings={blockSettings}
                content={componentData.content}
                className={'c-join-strip'}
            />
        )
    })
}

JoinStrip.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient'])
        .isRequired,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

JoinStrip.defaultProps = {
    backgroundColour: 'gradient'
}

export default JoinStrip

export const query = graphql`
    fragment JoinStripForPageBuilder on WpPage_Acfpagebuilder_Layouts_JoinStrip {
        fieldGroupName
        backgroundColour
    }
`
